import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
    en: {
        translation: {
            "(NOT SECURE)": "(NOT SECURE)",
            "Active?": "Active?",
            "API Documentation": "API Documentation",
            "At Least #": "At Least {{ lower }}",
            "At Most #": "At Most {{ upper }}",
            "Auto-Detect": "Auto-Detect",
            "Average per Chunk": "Average per Chunk",
            "Back to WORLD": "Back to <0 />",
            "Base Prestige": "Base Prestige",
            "Beacon Boundary_plural": "Beacon Boundaries",
            "Beacon Boundary": "Beacon Boundary",
            "Beacon Name": "Beacon Name",
            "Best World Type": "Best World Type",
            "Between # and #": "Between {{ lower }} and {{ upper }}",
            "Block Colors": "Block Colors",
            "Block Drops": "Block Drops",
            "Boundlexx - Unofficial Boundless API": "Boundlexx - Unofficial Boundless API",
            "Boundlexx is an unoffical API for the game Boundless. There is no affiliation with Wonderstruck Games":
                "Boundlexx is an unoffical API for the game Boundless. There is no affiliation with Wonderstruck Games",
            "Boundlexx UI Updates": "Boundlexx UI Updates",
            "Browser Selected": "Browser Selected",
            "By Resource": "By Resource",
            "Can also be crafted in MACHINE": "Can also be crafted in <0 />",
            "Can Claim?": "Can Claim?",
            "Can Edit?": "Can Edit?",
            "Can Everyone create beacons and plot on your world?":
                "Can Everyone create beacons and plot on your world?",
            "Can Everyone edit blocks on your world (outside of plots)?":
                "Can Everyone edit blocks on your world (outside of plots)?",
            "Can Everyone warp/use portals to your world?": "Can Everyone warp/use portals to your world?",
            "Can Visit?": "Can Visit?",
            "Cannot open portal between these worlds": "Cannot open portal between these worlds",
            "Change Language": "Change Language",
            "Change Theme": "Change Theme",
            "Claim?": "Claim?",
            "Clear All": "Clear All",
            "Click here to see what changed.": "Click here to see what changed.",
            "Color FoundWithCount_plural": "{{ count }} Colors Found",
            "Color FoundWithCount": "{{ count }} Color Found",
            "Color Mixer": "Color Mixer",
            "Color-Cycling": "Color-Cycling",
            "Copy to Clipboard": "Copy to Clipboard",
            "Core Liquid": "Core Liquid",
            "Crafted in Hand": "Crafted in Hand",
            "Crafting Calculator": "Crafting Calculator",
            "Creative World": "Creative World",
            "Creature Drops": "Creature Drops",
            "Current Colors": "Current Colors",
            "Current Resources": "Current Resources",
            "Damage Calculator": "Damage Calculator",
            "Default Colors": "Default Colors",
            "Directions to help players find the portal to your world":
                "Directions to help players find the portal to your world",
            "Distance Below Surface": "Distance Below Surface",
            "Distance Calculator": "Distance Calculator",
            "Do you plan to renew this world?": "Do you plan to renew this world?",
            "Does Not Expire": "Does Not Expire",
            "Edit?": "Edit?",
            "Embedded Resource": "Embedded Resource",
            "Embedded Resources": "Embedded Resources",
            "Emoji FoundWithCount_plural": "{{ count }} Emojis Found",
            "Emoji FoundWithCount": "{{ count }} Emoji Found",
            "EU Central": "EU Central",
            "Exo Embedded Resource": "Exo Embedded Resource",
            "Exo Surface Resource": "Exo Surface Resource",
            "Farming Data": "Farming Data",
            "Filters cannot be hidden when you have active filters. Clear existing filters before hiding them.":
                "Filters cannot be hidden when you have active filters. Clear existing filters before hiding them.",
            "Find By Color": "Find By Color",
            "Find Color": "Find Color",
            "Find Color/World By Item_plural": "Find Colors/Worlds By Item",
            "Find Item/World By Color_plural": "Find Items/Worlds By Color",
            "Find World by Resource_plural": "Find World by Resource",
            "Find Worlds by Color": "Find Worlds by Color",
            "Find Worlds with Resource": "Find Worlds with Resource",
            "First seen": "First seen",
            "Forge Simulator": "Forge Simulator",
            "Forge Traits": "Forge Traits",
            "Forum Generator": "Forum Generator",
            "Forum Post": "Forum Post",
            "Forum Template Generator": "Forum Template Generator",
            "Generate Another": "Generate Another",
            "Generate Template": "Generate Template",
            "Generating Template...": "Generating Template...",
            "Gleambow Racing": "Gleambow Racing",
            "Goo Helper": "Goo Helper",
            "Great City": "Great City",
            "Has Colors?": "Has Colors?",
            "Hide Filters": "Hide Filters",
            "Hide Groups": "Hide Groups",
            "Hiding Filters": "Hiding Filters",
            "In-game Name_plural": "In-game Names",
            "In-game Name": "In-game Name",
            "Initial Resources": "Initial Resources",
            "Is Beacon compactness enabled?": "Is Beacon compactness enabled?",
            "Is Locked?": "Is Locked?",
            "Is Resource?": "Is Resource?",
            "Item FoundWithCount_plural": "{{ count }} Items Found",
            "Item FoundWithCount": "{{ count }} Item Found",
            "Item Name": "Item Name",
            "Last appeared": "Last appeared",
            "Last available transform": "Last available transform",
            "Last Updated": "Last Updated",
            "LIQUID Preferred": "<0 /> Preferred",
            "LIQUID Required": "<0 /> Required",
            "Loading Color..._plural": "Loading Colors...",
            "Loading Colors...": "Loading Colors...",
            "Loading Data..._plural": "Loading Data...",
            "Loading Distance...": "Loading Distance...",
            "Loading Item..._plural": "Loading Items...",
            "Loading Item...": "Loading Item...",
            "Loading Recipe..._plural": "Loading Recipes...",
            "Loading Recipe...": "Loading Recipe...",
            "Loading Request Basket..._plural": "Loading Request Baskets...",
            "Loading Resource..._plural": "Loading Resources...",
            "Loading Resources...": "Loading Resources...",
            "Loading Shop Stand..._plural": "Loading Shop Stands...",
            "Loading Sovereign Color..._plural": "Loading Sovereign Colors...",
            "Loading World..._plural": "Loading Worlds...",
            "Loading World...": "Loading World...",
            "Loading Worlds...": "Loading Worlds...",
            "Main Type": "Main Type",
            "Max Life": "Max Life",
            "Max World Tier": "Max World Tier",
            "Min World Tier": "Min World Tier",
            "Min. Conduits": "Min. Conduits",
            "Min. Cost": "Min. Cost",
            "Min. Open Cost": "Min. Open Cost",
            "Mint Value": "Mint Value",
            "Misc.": "Misc.",
            "New Exoworld color!": "New Exoworld color!",
            "New Sovereign Color!": "New Sovereign Color!",
            "No distance information found": "No distance information found",
            "No Group": "No Group",
            "No patch notes found": "No patch notes found",
            "No Recipes Found": "No Recipes Found",
            "No Value": "No Value",
            "Number of Plots": "Number of Plots",
            "Number of Regions": "Number of Regions",
            "Oort shards": "Oort shards",
            "Oort shards/hr": "Oort shards/hr",
            "Orbited World": "Orbited World",
            "Page Not Found": "Page Not Found",
            "Portal Directions": "Portal Directions",
            "Portal Requirements": "Portal Requirements",
            "Post this in your post 'Body'": "Post this in your post 'Body'",
            "Post this in your post 'Title'": "Post this in your post 'Title'",
            "Provide an update link back to this form automatically filled out for quick update?":
                "Provide an update link back to this form automatically filled out for quick update?",
            "Public Visit?": "Public Visit?",
            "Recipe # of #": "Recipe {{ current }} of {{ total }}",
            "Recipe FoundWithCount_plural": "{{ count }} Recipe",
            "Recipe FoundWithCount": "{{ count }} Recipe",
            "Request Basket FoundWithCount_plural": "{{ count }} Request Baskets",
            "Request Basket FoundWithCount": "{{ count }} Request Basket",
            "Request Basket_plural": "Request Baskets",
            "Request Basket": "Request Basket",
            "Requires # Level in Skill and a Pie_plural": "Requires {{ count }} Levels in Skill and a Pie",
            "Requires # Level in Skill_plural": "Requires {{ count }} Levels in Skill",
            "Requires # Level in Skill": "Requires {{ count }} Level in Skill",
            "Requires Backer Tier": "Requires Backer Tier",
            "Requires Event": "Requires Event",
            "Requires Level": "Requires Level",
            "Requires Machine": "Requires Machine",
            "Requires Skill": "Requires Skill",
            "Reset Data": "Reset Data",
            "Resource Block": "Resource Block",
            "Resource Data": "Resource Data",
            "Resources (Blocks)": "Resources (Blocks)",
            "Search Color": "Search Colors",
            "Search Emoji": "Search Emojis",
            "Search Item": "Search Items",
            "Search World": "Search Worlds",
            "See Updates": "See Updates",
            "Select Category": "Select Category",
            "Select Color": "Select Color",
            "Select Item": "Select Item",
            "Select Orbited World": "Select Orbited World",
            "Select Subtype": "Select Subtype",
            "Select Type": "Select Type",
            "Select World 1": "Select World 1",
            "Select World 2": "Select World 2",
            "Select World": "Select World",
            "Server Region": "Server Region",
            "Shop Stand FoundWithCount_plural": "{{ count }} Shop Stands",
            "Shop Stand FoundWithCount": "{{ count }} Shop Stand",
            "Shop Stand_plural": "Shop Stands",
            "Shop Stand": "Shop Stand",
            "Show Filters": "Show Filters",
            "Show Groups": "Show Groups",
            "Show on Atlas": "Show on Atlas",
            "Sovereign Color FoundWithCount_plural": "{{ count }} Sovereign Colors",
            "Sovereign Color FoundWithCount": "{{ count }} Sovereign Color",
            "Sovereign Color_plural": "Sovereign Colors",
            "Sovereign Details": "Sovereign Details",
            "Sovereign Item Color_plural": "Sovereign Item Colors",
            "Sovereign World": "Sovereign World",
            "Surface Liquid": "Surface Liquid",
            "Surface Resource": "Surface Resource",
            "Surface Resources": "Surface Resources",
            "The Boundless Lexicon": "The Boundless Lexicon",
            "There is a new version of Boundlexx UI.": "There is a new version of Boundlexx UI.",
            "Tint from": "Tint from",
            "Unknown Error occured. Please try again later.": "Unknown Error occured. Please try again later.",
            "Update Link?": "Update Link?",
            "Update Now": "Update Now",
            "Updates will automatically be applied when you close all tabs":
                "Updates will automatically be applied when you close all tabs",
            "US East": "US East",
            "US West": "US West",
            "Used In_plural": "Used In",
            "Warp Cost": "Warp Cost",
            "Will Renew?": "Will Renew?",
            "World 1": "World 1",
            "World 2": "World 2",
            "World Atlas": "World Atlas",
            "World Capital": "World Capital",
            "World Class": "World Class",
            "World FoundWithCount_plural": "{{ count }} Worlds Found",
            "World FoundWithCount": "{{ count }} World Found",
            "World Image": "World Image",
            "World Name": "World Name",
            "World Rank": "World Rank",
            "World Tier": "World Tier",
            "World Type": "World Type",
            "Your Boundless Forums Username": "Your Boundless Forums Username",
            Active: "Active",
            ACTIVITIES: "Activities",
            Adventurer: "Adventurer",
            Altitude: "Altitude",
            Amethyst: "Amethyst",
            ANIMAL: "Animals & Nature",
            Any: "Any",
            Atlas: "Atlas",
            Atmosphere: "Atmosphere",
            Australia: "Australia",
            Beacon_plural: "Beacons",
            Beacon: "Beacon",
            Best: "Best",
            Birthday: "Birthday",
            Blast: "Blast",
            Blink: "Blink",
            Body: "Body",
            BOUNDLESS: "Boundless",
            Boundlexx: "Boundlexx",
            Browse: "Browse",
            Brutal: "Brutal",
            Bulk: "Bulk",
            Burn: "Burn",
            Campfire: "Campfire",
            Category: "Category",
            Chieftain: "Chieftain",
            Chill: "Chill",
            City: "City",
            Close: "Close",
            Coal: "Coal",
            Color_plural: "Colors",
            Color: "Color",
            Compactness: "Compactness",
            COMPONENT: "Component",
            Corrosive: "Corrosive",
            Count: "Count",
            Dark: "Dark",
            Data_plural: "Data",
            Data: "Data",
            Diamond: "Diamond",
            Distance: "Distance",
            Emerald: "Emerald",
            Emoji_plural: "Emojis",
            Emoji: "Emoji",
            End: "End",
            Error: "Error",
            Exoworld: "Exoworld",
            Explorer: "Explorer",
            Fierce: "Fierce",
            FLAGS: "Flags",
            Flower: "Flower",
            Foliage: "Foliage",
            FOOD: "Food & Drink",
            Fungus: "Fungus",
            Furance: "Furance",
            Gleam: "Gleam",
            Grass: "Grass",
            Halloween: "Halloween",
            Hamlet: "Hamlet",
            Help: "Help",
            Homeworld: "Homeworld",
            ID_plural: "IDs",
            ID: "ID",
            Inactive: "Inactive",
            Inhospitable: "Inhospitable",
            Inputs: "Inputs",
            Item_plural: "Items",
            Item: "Item",
            Language: "Language",
            Level: "Level",
            Light: "Light",
            Location: "Location",
            Locked: "Locked",
            Lovestruck: "Lovestruck",
            Lucent: "Lucent",
            Lush: "Lush",
            Machine: "Machine",
            Mass: "Mass",
            Master: "Master",
            Mayor: "Mayor",
            Metal: "Metal",
            Mine: "Mine",
            Miscellaneous: "Miscellaneous",
            Neutral: "Neutral",
            Next: "Next",
            No: "No",
            None: "None",
            Normal: "Normal",
            OBJECTS: "Objects",
            Oortian: "Oortian",
            Oortmas: "Oortmas",
            Outpost: "Outpost",
            Output: "Output",
            Pathfinder: "Pathfinder",
            PEOPLE: "People & Body",
            Percent: "Percent",
            Permissions: "Permissions",
            Pioneer: "Pioneer",
            Place: "Place",
            Placid: "Placid",
            Plant: "Plant",
            Power: "Power",
            Prestige: "Prestige",
            Prev: "Prev",
            Price: "Price",
            Recipe_plural: "Recipes",
            Recipe: "Recipe",
            Region: "Region",
            Resources: "Resources",
            Rift: "Rift",
            Rock: "Rock",
            Ruby: "Ruby",
            Rugged: "Rugged",
            Sapphire: "Sapphire",
            Savage: "Savage",
            Settlement_plural: "Settlements",
            Settlement: "Settlement",
            Shock: "Shock",
            Single: "Single",
            Size: "Size",
            SMILEY: "Smiley & Emotion",
            Soil: "Soil",
            Sovereign: "Sovereign",
            Start: "Start",
            Status: "Status",
            Subtype: "Subtype",
            SYMBOLS: "Symbols",
            Temperate: "Temperate",
            Theme: "Theme",
            Tier: "Tier",
            Title: "Title",
            Tool_plural: "Tools",
            Topaz: "Topaz",
            Town: "Town",
            Toxic: "Toxic",
            TRAVEL: "Travel & Places",
            Turbulent: "Turbulent",
            Type: "Type",
            Umbris: "Umbris",
            UNCATEGORIZED: "Uncategorized",
            Unknown: "Unknown",
            Username: "Username",
            Village: "Village",
            Voyager: "Voyager",
            Wayfarer: "Wayfarer",
            Wood: "Wood",
            World_plural: "Worlds",
            World: "World",
            XP: "XP",
            Yes: "Yes",

            Block: "Block",
            "Color Variation": "Color Variation",
            "Color Variation_plural": "Color Variations",
            "Loading Color Variation..._plural": "Color Variations...",
            "Color Variation FoundWithCount_plural": "{{ count }} Color Variations Found",
            "Color Variation FoundWithCount": "{{ count }} Color Variation Found",
            "Change Universe": "Change Universe",
            Universe: "Universe",
            "Metal Variation": "Metal Variation",
            "Metal Variation_plural": "Metal Variations",
            "Loading Metal Variation..._plural": "Metal Variations...",
            "Metal Variation FoundWithCount_plural": "{{ count }} Metal Variations Found",
            "Metal Variation FoundWithCount": "{{ count }} Metal Variation Found",
            "Unexpected Error": "Unexpected Error",
            "Reload Application": "Reload Application",
            "Reset Application": "Reset Application",
            "Farming Guide": "Farming Guide",
            "Forging Reference": "Forging Reference",
            "Color Shade": "Color Shade",
            "Color Group": "Color Group",
            "Base Color": "Base Color",
            Black: "Black",
            Shadow: "Shadow",
            Night: "Night",
            Strong: "Strong",
            Deep: "Deep",
            Hot: "Hot",
            Silk: "Silk",
            Oxide: "Oxide",
            Pure: "Pure",
            Warm: "Warm",
            Slate: "Slate",
            Rust: "Rust",
            Vidid: "Vidid",
            Pale: "Pale",
            Ashen: "Ashen",
            Bright: "Bright",
            Stark: "Stark",
            Cool: "Cool",
            Weary: "Weary",
            Luminous: "Luminous",
            Crisp: "Crisp",
            Cold: "Cold",
            Azure: "Azure",
            Cerulean: "Cerulean",
            Cobalt: "Cobalt",
            Blue: "Blue",
            Lavender: "Lavender",
            Lilac: "Lilac",
            White: "White",
            Magenta: "Magenta",
            Violet: "Violet",
            Berry: "Berry",
            Fuchsia: "Fuchsia",
            Cherry: "Cherry",
            Red: "Red",
            Rose: "Rose",
            Orange: "Orange",
            Sepia: "Sepia",
            Taupe: "Taupe",
            Mustard: "Mustard",
            Tan: "Tan",
            Yellow: "Yellow",
            Lime: "Lime",
            Moss: "Moss",
            Mint: "Mint",
            Teal: "Teal",
            Viridian: "Viridian",
            Turquoise: "Turquoise",
            "Black/Grey/White": "Black/Grey/White",
            Green: "Green",
        },
    },
    fr: {
        translation: {
            "(NOT SECURE)": "(NOT SECURE)",
            "Active?": "Active?",
            "API Documentation": "API Documentation",
            "At Least #": "At Least {{ lower }}",
            "At Most #": "At Most {{ upper }}",
            "Auto-Detect": "Auto-Detect",
            "Average per Chunk": "Average per Chunk",
            "Back to WORLD": "Back to <0 />",
            "Base Prestige": "Base Prestige",
            "Beacon Boundary_plural": "Beacon Boundaries",
            "Beacon Boundary": "Beacon Boundary",
            "Beacon Name": "Beacon Name",
            "Best World Type": "Best World Type",
            "Between # and #": "Between {{ lower }} and {{ upper }}",
            "Block Colors": "Block Colors",
            "Block Drops": "Block Drops",
            "Boundlexx - Unofficial Boundless API": "Boundlexx - Unofficial Boundless API",
            "Boundlexx is an unoffical API for the game Boundless. There is no affiliation with Wonderstruck Games":
                "Boundlexx is an unoffical API for the game Boundless. There is no affiliation with Wonderstruck Games",
            "Boundlexx UI Updates": "Boundlexx UI Updates",
            "Browser Selected": "Browser Selected",
            "By Resource": "By Resource",
            "Can also be crafted in MACHINE": "Can also be crafted in <0 />",
            "Can Claim?": "Can Claim?",
            "Can Edit?": "Can Edit?",
            "Can Everyone create beacons and plot on your world?":
                "Can Everyone create beacons and plot on your world?",
            "Can Everyone edit blocks on your world (outside of plots)?":
                "Can Everyone edit blocks on your world (outside of plots)?",
            "Can Everyone warp/use portals to your world?": "Can Everyone warp/use portals to your world?",
            "Can Visit?": "Can Visit?",
            "Cannot open portal between these worlds": "Cannot open portal between these worlds",
            "Change Language": "Change Language",
            "Change Theme": "Change Theme",
            "Claim?": "Claim?",
            "Clear All": "Clear All",
            "Click here to see what changed.": "Click here to see what changed.",
            "Color FoundWithCount_plural": "{{ count }} Colors Found",
            "Color FoundWithCount": "{{ count }} Color Found",
            "Color Mixer": "Color Mixer",
            "Color-Cycling": "Color-Cycling",
            "Copy to Clipboard": "Copy to Clipboard",
            "Core Liquid": "Core Liquid",
            "Crafted in Hand": "Crafted in Hand",
            "Crafting Calculator": "Crafting Calculator",
            "Creative World": "Creative World",
            "Creature Drops": "Creature Drops",
            "Current Colors": "Current Colors",
            "Current Resources": "Current Resources",
            "Damage Calculator": "Damage Calculator",
            "Default Colors": "Default Colors",
            "Directions to help players find the portal to your world":
                "Directions to help players find the portal to your world",
            "Distance Below Surface": "Distance Below Surface",
            "Distance Calculator": "Distance Calculator",
            "Do you plan to renew this world?": "Do you plan to renew this world?",
            "Does Not Expire": "Does Not Expire",
            "Edit?": "Edit?",
            "Embedded Resource": "Embedded Resource",
            "Embedded Resources": "Embedded Resources",
            "Emoji FoundWithCount_plural": "{{ count }} Emojis Found",
            "Emoji FoundWithCount": "{{ count }} Emoji Found",
            "EU Central": "EU Central",
            "Exo Embedded Resource": "Exo Embedded Resource",
            "Exo Surface Resource": "Exo Surface Resource",
            "Farming Data": "Farming Data",
            "Filters cannot be hidden when you have active filters. Clear existing filters before hiding them.":
                "Filters cannot be hidden when you have active filters. Clear existing filters before hiding them.",
            "Find By Color": "Find By Color",
            "Find Color": "Find Color",
            "Find Color/World By Item_plural": "Find Colors/Worlds By Item",
            "Find Item/World By Color_plural": "Find Items/Worlds By Color",
            "Find World by Resource_plural": "Find World by Resource",
            "Find Worlds by Color": "Find Worlds by Color",
            "Find Worlds with Resource": "Find Worlds with Resource",
            "First seen": "First seen",
            "Forge Simulator": "Forge Simulator",
            "Forge Traits": "Forge Traits",
            "Forum Generator": "Forum Generator",
            "Forum Post": "Forum Post",
            "Forum Template Generator": "Forum Template Generator",
            "Generate Another": "Generate Another",
            "Generate Template": "Generate Template",
            "Generating Template...": "Generating Template...",
            "Gleambow Racing": "Gleambow Racing",
            "Goo Helper": "Goo Helper",
            "Great City": "Great City",
            "Has Colors?": "Has Colors?",
            "Hide Filters": "Hide Filters",
            "Hide Groups": "Hide Groups",
            "Hiding Filters": "Hiding Filters",
            "In-game Name_plural": "In-game Names",
            "In-game Name": "In-game Name",
            "Initial Resources": "Initial Resources",
            "Is Beacon compactness enabled?": "Is Beacon compactness enabled?",
            "Is Locked?": "Is Locked?",
            "Is Resource?": "Is Resource?",
            "Item FoundWithCount_plural": "{{ count }} Items Found",
            "Item FoundWithCount": "{{ count }} Item Found",
            "Item Name": "Item Name",
            "Last appeared": "Last appeared",
            "Last available transform": "Last available transform",
            "Last Updated": "Last Updated",
            "LIQUID Preferred": "<0 /> Preferred",
            "LIQUID Required": "<0 /> Required",
            "Loading Color..._plural": "Loading Colors...",
            "Loading Colors...": "Loading Colors...",
            "Loading Data..._plural": "Loading Data...",
            "Loading Distance...": "Loading Distance...",
            "Loading Item..._plural": "Loading Items...",
            "Loading Item...": "Loading Item...",
            "Loading Recipe..._plural": "Loading Recipes...",
            "Loading Recipe...": "Loading Recipe...",
            "Loading Request Basket..._plural": "Loading Request Baskets...",
            "Loading Resource..._plural": "Loading Resources...",
            "Loading Resources...": "Loading Resources...",
            "Loading Shop Stand..._plural": "Loading Shop Stands...",
            "Loading Sovereign Color..._plural": "Loading Sovereign Colors...",
            "Loading World..._plural": "Loading Worlds...",
            "Loading World...": "Loading World...",
            "Loading Worlds...": "Loading Worlds...",
            "Main Type": "Main Type",
            "Max Life": "Max Life",
            "Max World Tier": "Max World Tier",
            "Min World Tier": "Min World Tier",
            "Min. Conduits": "Min. Conduits",
            "Min. Cost": "Min. Cost",
            "Min. Open Cost": "Min. Open Cost",
            "Mint Value": "Mint Value",
            "Misc.": "Misc.",
            "New Exoworld color!": "New Exoworld color!",
            "New Sovereign Color!": "New Sovereign Color!",
            "No distance information found": "No distance information found",
            "No Group": "No Group",
            "No patch notes found": "No patch notes found",
            "No Recipes Found": "No Recipes Found",
            "No Value": "No Value",
            "Number of Plots": "Number of Plots",
            "Number of Regions": "Number of Regions",
            "Oort shards": "Oort shards",
            "Oort shards/hr": "Oort shards/hr",
            "Orbited World": "Orbited World",
            "Page Not Found": "Page Not Found",
            "Portal Directions": "Portal Directions",
            "Portal Requirements": "Portal Requirements",
            "Post this in your post 'Body'": "Post this in your post 'Body'",
            "Post this in your post 'Title'": "Post this in your post 'Title'",
            "Provide an update link back to this form automatically filled out for quick update?":
                "Provide an update link back to this form automatically filled out for quick update?",
            "Public Visit?": "Public Visit?",
            "Recipe # of #": "Recipe {{ current }} of {{ total }}",
            "Recipe FoundWithCount_plural": "{{ count }} Recipe",
            "Recipe FoundWithCount": "{{ count }} Recipe",
            "Request Basket FoundWithCount_plural": "{{ count }} Request Baskets",
            "Request Basket FoundWithCount": "{{ count }} Request Basket",
            "Request Basket_plural": "Request Baskets",
            "Request Basket": "Request Basket",
            "Requires # Level in Skill and a Pie_plural": "Requires {{ count }} Levels in Skill and a Pie",
            "Requires # Level in Skill_plural": "Requires {{ count }} Levels in Skill",
            "Requires # Level in Skill": "Requires {{ count }} Level in Skill",
            "Requires Backer Tier": "Requires Backer Tier",
            "Requires Event": "Requires Event",
            "Requires Level": "Requires Level",
            "Requires Machine": "Requires Machine",
            "Requires Skill": "Requires Skill",
            "Reset Data": "Reset Data",
            "Resource Block": "Resource Block",
            "Resource Data": "Resource Data",
            "Resources (Blocks)": "Resources (Blocks)",
            "Search Color": "Search Colors",
            "Search Emoji": "Search Emojis",
            "Search Item": "Search Items",
            "Search World": "Search Worlds",
            "See Updates": "See Updates",
            "Select Category": "Select Category",
            "Select Color": "Select Color",
            "Select Item": "Select Item",
            "Select Orbited World": "Select Orbited World",
            "Select Subtype": "Select Subtype",
            "Select Type": "Select Type",
            "Select World 1": "Select World 1",
            "Select World 2": "Select World 2",
            "Select World": "Select World",
            "Server Region": "Server Region",
            "Shop Stand FoundWithCount_plural": "{{ count }} Shop Stands",
            "Shop Stand FoundWithCount": "{{ count }} Shop Stand",
            "Shop Stand_plural": "Shop Stands",
            "Shop Stand": "Shop Stand",
            "Show Filters": "Show Filters",
            "Show Groups": "Show Groups",
            "Show on Atlas": "Show on Atlas",
            "Sovereign Color FoundWithCount_plural": "{{ count }} Sovereign Colors",
            "Sovereign Color FoundWithCount": "{{ count }} Sovereign Color",
            "Sovereign Color_plural": "Sovereign Colors",
            "Sovereign Details": "Sovereign Details",
            "Sovereign Item Color_plural": "Sovereign Item Colors",
            "Sovereign World": "Sovereign World",
            "Surface Liquid": "Surface Liquid",
            "Surface Resource": "Surface Resource",
            "Surface Resources": "Surface Resources",
            "The Boundless Lexicon": "The Boundless Lexicon",
            "There is a new version of Boundlexx UI.": "There is a new version of Boundlexx UI.",
            "Tint from": "Tint from",
            "Unknown Error occured. Please try again later.": "Unknown Error occured. Please try again later.",
            "Update Link?": "Update Link?",
            "Update Now": "Update Now",
            "Updates will automatically be applied when you close all tabs":
                "Updates will automatically be applied when you close all tabs",
            "US East": "US East",
            "US West": "US West",
            "Used In_plural": "Used In",
            "Warp Cost": "Warp Cost",
            "Will Renew?": "Will Renew?",
            "World 1": "World 1",
            "World 2": "World 2",
            "World Atlas": "World Atlas",
            "World Capital": "World Capital",
            "World Class": "World Class",
            "World FoundWithCount_plural": "{{ count }} Worlds Found",
            "World FoundWithCount": "{{ count }} World Found",
            "World Image": "World Image",
            "World Name": "World Name",
            "World Rank": "World Rank",
            "World Tier": "World Tier",
            "World Type": "World Type",
            "Your Boundless Forums Username": "Your Boundless Forums Username",
            Active: "Active",
            ACTIVITIES: "Activities",
            Adventurer: "Adventurer",
            Altitude: "Altitude",
            Amethyst: "Amethyst",
            ANIMAL: "Animals & Nature",
            Any: "Any",
            Atlas: "Atlas",
            Atmosphere: "Atmosphere",
            Australia: "Australia",
            Beacon_plural: "Beacons",
            Beacon: "Beacon",
            Best: "Best",
            Birthday: "Birthday",
            Blast: "Blast",
            Blink: "Blink",
            Body: "Body",
            BOUNDLESS: "Boundless",
            Boundlexx: "Boundlexx",
            Browse: "Browse",
            Brutal: "Brutal",
            Bulk: "Bulk",
            Burn: "Burn",
            Campfire: "Campfire",
            Category: "Category",
            Chieftain: "Chieftain",
            Chill: "Chill",
            City: "City",
            Close: "Close",
            Coal: "Coal",
            Color_plural: "Colors",
            Color: "Color",
            Compactness: "Compactness",
            COMPONENT: "Component",
            Corrosive: "Corrosive",
            Count: "Count",
            Dark: "Dark",
            Data_plural: "Data",
            Data: "Data",
            Diamond: "Diamond",
            Distance: "Distance",
            Emerald: "Emerald",
            Emoji_plural: "Emojis",
            Emoji: "Emoji",
            End: "End",
            Error: "Error",
            Exoworld: "Exoworld",
            Explorer: "Explorer",
            Fierce: "Fierce",
            FLAGS: "Flags",
            Flower: "Flower",
            Foliage: "Foliage",
            FOOD: "Food & Drink",
            Fungus: "Fungus",
            Furance: "Furance",
            Gleam: "Gleam",
            Grass: "Grass",
            Halloween: "Halloween",
            Hamlet: "Hamlet",
            Help: "Help",
            Homeworld: "Homeworld",
            ID_plural: "IDs",
            ID: "ID",
            Inactive: "Inactive",
            Inhospitable: "Inhospitable",
            Inputs: "Inputs",
            Item_plural: "Items",
            Item: "Item",
            Language: "Language",
            Level: "Level",
            Light: "Light",
            Location: "Location",
            Locked: "Locked",
            Lovestruck: "Lovestruck",
            Lucent: "Lucent",
            Lush: "Lush",
            Machine: "Machine",
            Mass: "Mass",
            Master: "Master",
            Mayor: "Mayor",
            Metal: "Metal",
            Mine: "Mine",
            Miscellaneous: "Miscellaneous",
            Neutral: "Neutral",
            Next: "Next",
            No: "No",
            None: "None",
            Normal: "Normal",
            OBJECTS: "Objects",
            Oortian: "Oortian",
            Oortmas: "Oortmas",
            Outpost: "Outpost",
            Output: "Output",
            Pathfinder: "Pathfinder",
            PEOPLE: "People & Body",
            Percent: "Percent",
            Permissions: "Permissions",
            Pioneer: "Pioneer",
            Place: "Place",
            Placid: "Placid",
            Plant: "Plant",
            Power: "Power",
            Prestige: "Prestige",
            Prev: "Prev",
            Price: "Price",
            Recipe_plural: "Recipes",
            Recipe: "Recipe",
            Region: "Region",
            Resources: "Resources",
            Rift: "Rift",
            Rock: "Rock",
            Ruby: "Ruby",
            Rugged: "Rugged",
            Sapphire: "Sapphire",
            Savage: "Savage",
            Settlement_plural: "Settlements",
            Settlement: "Settlement",
            Shock: "Shock",
            Single: "Single",
            Size: "Size",
            SMILEY: "Smiley & Emotion",
            Soil: "Soil",
            Sovereign: "Sovereign",
            Start: "Start",
            Status: "Status",
            Subtype: "Subtype",
            SYMBOLS: "Symbols",
            Temperate: "Temperate",
            Theme: "Theme",
            Tier: "Tier",
            Title: "Title",
            Tool_plural: "Tools",
            Topaz: "Topaz",
            Town: "Town",
            Toxic: "Toxic",
            TRAVEL: "Travel & Places",
            Turbulent: "Turbulent",
            Type: "Type",
            Umbris: "Umbris",
            UNCATEGORIZED: "Uncategorized",
            Unknown: "Unknown",
            Username: "Username",
            Village: "Village",
            Voyager: "Voyager",
            Wayfarer: "Wayfarer",
            Wood: "Wood",
            World_plural: "Worlds",
            World: "World",
            XP: "XP",
            Yes: "Yes",

            Block: "Block",
            "Color Variation": "Color Variation",
            "Color Variation_plural": "Color Variations",
            "Loading Color Variation..._plural": "Color Variations...",
            "Color Variation FoundWithCount_plural": "{{ count }} Color Variations Found",
            "Color Variation FoundWithCount": "{{ count }} Color Variation Found",
            "Change Universe": "Change Universe",
            Universe: "Universe",
            "Metal Variation": "Metal Variation",
            "Metal Variation_plural": "Metal Variations",
            "Loading Metal Variation..._plural": "Metal Variations...",
            "Metal Variation FoundWithCount_plural": "{{ count }} Metal Variations Found",
            "Metal Variation FoundWithCount": "{{ count }} Metal Variation Found",
            "Unexpected Error": "Unexpected Error",
            "Reload Application": "Reload Application",
            "Reset Application": "Reset Application",
        },
    },
    de: {
        translation: {
            "(NOT SECURE)": "(NOT SECURE)",
            "Active?": "Active?",
            "API Documentation": "API Documentation",
            "At Least #": "At Least {{ lower }}",
            "At Most #": "At Most {{ upper }}",
            "Auto-Detect": "Auto-Detect",
            "Average per Chunk": "Average per Chunk",
            "Back to WORLD": "Back to <0 />",
            "Base Prestige": "Base Prestige",
            "Beacon Boundary_plural": "Beacon Boundaries",
            "Beacon Boundary": "Beacon Boundary",
            "Beacon Name": "Beacon Name",
            "Best World Type": "Best World Type",
            "Between # and #": "Between {{ lower }} and {{ upper }}",
            "Block Colors": "Block Colors",
            "Block Drops": "Block Drops",
            "Boundlexx - Unofficial Boundless API": "Boundlexx - Unofficial Boundless API",
            "Boundlexx is an unoffical API for the game Boundless. There is no affiliation with Wonderstruck Games":
                "Boundlexx is an unoffical API for the game Boundless. There is no affiliation with Wonderstruck Games",
            "Boundlexx UI Updates": "Boundlexx UI Updates",
            "Browser Selected": "Browser Selected",
            "By Resource": "By Resource",
            "Can also be crafted in MACHINE": "Can also be crafted in <0 />",
            "Can Claim?": "Can Claim?",
            "Can Edit?": "Can Edit?",
            "Can Everyone create beacons and plot on your world?":
                "Can Everyone create beacons and plot on your world?",
            "Can Everyone edit blocks on your world (outside of plots)?":
                "Can Everyone edit blocks on your world (outside of plots)?",
            "Can Everyone warp/use portals to your world?": "Can Everyone warp/use portals to your world?",
            "Can Visit?": "Can Visit?",
            "Cannot open portal between these worlds": "Cannot open portal between these worlds",
            "Change Language": "Change Language",
            "Change Theme": "Change Theme",
            "Claim?": "Claim?",
            "Clear All": "Clear All",
            "Click here to see what changed.": "Click here to see what changed.",
            "Color FoundWithCount_plural": "{{ count }} Colors Found",
            "Color FoundWithCount": "{{ count }} Color Found",
            "Color Mixer": "Color Mixer",
            "Color-Cycling": "Color-Cycling",
            "Copy to Clipboard": "Copy to Clipboard",
            "Core Liquid": "Core Liquid",
            "Crafted in Hand": "Crafted in Hand",
            "Crafting Calculator": "Crafting Calculator",
            "Creative World": "Creative World",
            "Creature Drops": "Creature Drops",
            "Current Colors": "Current Colors",
            "Current Resources": "Current Resources",
            "Damage Calculator": "Damage Calculator",
            "Default Colors": "Default Colors",
            "Directions to help players find the portal to your world":
                "Directions to help players find the portal to your world",
            "Distance Below Surface": "Distance Below Surface",
            "Distance Calculator": "Distance Calculator",
            "Do you plan to renew this world?": "Do you plan to renew this world?",
            "Does Not Expire": "Does Not Expire",
            "Edit?": "Edit?",
            "Embedded Resource": "Embedded Resource",
            "Embedded Resources": "Embedded Resources",
            "Emoji FoundWithCount_plural": "{{ count }} Emojis Found",
            "Emoji FoundWithCount": "{{ count }} Emoji Found",
            "EU Central": "EU Central",
            "Exo Embedded Resource": "Exo Embedded Resource",
            "Exo Surface Resource": "Exo Surface Resource",
            "Farming Data": "Farming Data",
            "Filters cannot be hidden when you have active filters. Clear existing filters before hiding them.":
                "Filters cannot be hidden when you have active filters. Clear existing filters before hiding them.",
            "Find By Color": "Find By Color",
            "Find Color": "Find Color",
            "Find Color/World By Item_plural": "Find Colors/Worlds By Item",
            "Find Item/World By Color_plural": "Find Items/Worlds By Color",
            "Find World by Resource_plural": "Find World by Resource",
            "Find Worlds by Color": "Find Worlds by Color",
            "Find Worlds with Resource": "Find Worlds with Resource",
            "First seen": "First seen",
            "Forge Simulator": "Forge Simulator",
            "Forge Traits": "Forge Traits",
            "Forum Generator": "Forum Generator",
            "Forum Post": "Forum Post",
            "Forum Template Generator": "Forum Template Generator",
            "Generate Another": "Generate Another",
            "Generate Template": "Generate Template",
            "Generating Template...": "Generating Template...",
            "Gleambow Racing": "Gleambow Racing",
            "Goo Helper": "Goo Helper",
            "Great City": "Great City",
            "Has Colors?": "Has Colors?",
            "Hide Filters": "Hide Filters",
            "Hide Groups": "Hide Groups",
            "Hiding Filters": "Hiding Filters",
            "In-game Name_plural": "In-game Names",
            "In-game Name": "In-game Name",
            "Initial Resources": "Initial Resources",
            "Is Beacon compactness enabled?": "Is Beacon compactness enabled?",
            "Is Locked?": "Is Locked?",
            "Is Resource?": "Is Resource?",
            "Item FoundWithCount_plural": "{{ count }} Items Found",
            "Item FoundWithCount": "{{ count }} Item Found",
            "Item Name": "Item Name",
            "Last appeared": "Last appeared",
            "Last available transform": "Last available transform",
            "Last Updated": "Last Updated",
            "LIQUID Preferred": "<0 /> Preferred",
            "LIQUID Required": "<0 /> Required",
            "Loading Color..._plural": "Loading Colors...",
            "Loading Colors...": "Loading Colors...",
            "Loading Data..._plural": "Loading Data...",
            "Loading Distance...": "Loading Distance...",
            "Loading Item..._plural": "Loading Items...",
            "Loading Item...": "Loading Item...",
            "Loading Recipe..._plural": "Loading Recipes...",
            "Loading Recipe...": "Loading Recipe...",
            "Loading Request Basket..._plural": "Loading Request Baskets...",
            "Loading Resource..._plural": "Loading Resources...",
            "Loading Resources...": "Loading Resources...",
            "Loading Shop Stand..._plural": "Loading Shop Stands...",
            "Loading Sovereign Color..._plural": "Loading Sovereign Colors...",
            "Loading World..._plural": "Loading Worlds...",
            "Loading World...": "Loading World...",
            "Loading Worlds...": "Loading Worlds...",
            "Main Type": "Main Type",
            "Max Life": "Max Life",
            "Max World Tier": "Max World Tier",
            "Min World Tier": "Min World Tier",
            "Min. Conduits": "Min. Conduits",
            "Min. Cost": "Min. Cost",
            "Min. Open Cost": "Min. Open Cost",
            "Mint Value": "Mint Value",
            "Misc.": "Misc.",
            "New Exoworld color!": "New Exoworld color!",
            "New Sovereign Color!": "New Sovereign Color!",
            "No distance information found": "No distance information found",
            "No Group": "No Group",
            "No patch notes found": "No patch notes found",
            "No Recipes Found": "No Recipes Found",
            "No Value": "No Value",
            "Number of Plots": "Number of Plots",
            "Number of Regions": "Number of Regions",
            "Oort shards": "Oort shards",
            "Oort shards/hr": "Oort shards/hr",
            "Orbited World": "Orbited World",
            "Page Not Found": "Page Not Found",
            "Portal Directions": "Portal Directions",
            "Portal Requirements": "Portal Requirements",
            "Post this in your post 'Body'": "Post this in your post 'Body'",
            "Post this in your post 'Title'": "Post this in your post 'Title'",
            "Provide an update link back to this form automatically filled out for quick update?":
                "Provide an update link back to this form automatically filled out for quick update?",
            "Public Visit?": "Public Visit?",
            "Recipe # of #": "Recipe {{ current }} of {{ total }}",
            "Recipe FoundWithCount_plural": "{{ count }} Recipe",
            "Recipe FoundWithCount": "{{ count }} Recipe",
            "Request Basket FoundWithCount_plural": "{{ count }} Request Baskets",
            "Request Basket FoundWithCount": "{{ count }} Request Basket",
            "Request Basket_plural": "Request Baskets",
            "Request Basket": "Request Basket",
            "Requires # Level in Skill and a Pie_plural": "Requires {{ count }} Levels in Skill and a Pie",
            "Requires # Level in Skill_plural": "Requires {{ count }} Levels in Skill",
            "Requires # Level in Skill": "Requires {{ count }} Level in Skill",
            "Requires Backer Tier": "Requires Backer Tier",
            "Requires Event": "Requires Event",
            "Requires Level": "Requires Level",
            "Requires Machine": "Requires Machine",
            "Requires Skill": "Requires Skill",
            "Reset Data": "Reset Data",
            "Resource Block": "Resource Block",
            "Resource Data": "Resource Data",
            "Resources (Blocks)": "Resources (Blocks)",
            "Search Color": "Search Colors",
            "Search Emoji": "Search Emojis",
            "Search Item": "Search Items",
            "Search World": "Search Worlds",
            "See Updates": "See Updates",
            "Select Category": "Select Category",
            "Select Color": "Select Color",
            "Select Item": "Select Item",
            "Select Orbited World": "Select Orbited World",
            "Select Subtype": "Select Subtype",
            "Select Type": "Select Type",
            "Select World 1": "Select World 1",
            "Select World 2": "Select World 2",
            "Select World": "Select World",
            "Server Region": "Server Region",
            "Shop Stand FoundWithCount_plural": "{{ count }} Shop Stands",
            "Shop Stand FoundWithCount": "{{ count }} Shop Stand",
            "Shop Stand_plural": "Shop Stands",
            "Shop Stand": "Shop Stand",
            "Show Filters": "Show Filters",
            "Show Groups": "Show Groups",
            "Show on Atlas": "Show on Atlas",
            "Sovereign Color FoundWithCount_plural": "{{ count }} Sovereign Colors",
            "Sovereign Color FoundWithCount": "{{ count }} Sovereign Color",
            "Sovereign Color_plural": "Sovereign Colors",
            "Sovereign Details": "Sovereign Details",
            "Sovereign Item Color_plural": "Sovereign Item Colors",
            "Sovereign World": "Sovereign World",
            "Surface Liquid": "Surface Liquid",
            "Surface Resource": "Surface Resource",
            "Surface Resources": "Surface Resources",
            "The Boundless Lexicon": "The Boundless Lexicon",
            "There is a new version of Boundlexx UI.": "There is a new version of Boundlexx UI.",
            "Tint from": "Tint from",
            "Unknown Error occured. Please try again later.": "Unknown Error occured. Please try again later.",
            "Update Link?": "Update Link?",
            "Update Now": "Update Now",
            "Updates will automatically be applied when you close all tabs":
                "Updates will automatically be applied when you close all tabs",
            "US East": "US East",
            "US West": "US West",
            "Used In_plural": "Used In",
            "Warp Cost": "Warp Cost",
            "Will Renew?": "Will Renew?",
            "World 1": "World 1",
            "World 2": "World 2",
            "World Atlas": "World Atlas",
            "World Capital": "World Capital",
            "World Class": "World Class",
            "World FoundWithCount_plural": "{{ count }} Worlds Found",
            "World FoundWithCount": "{{ count }} World Found",
            "World Image": "World Image",
            "World Name": "World Name",
            "World Rank": "World Rank",
            "World Tier": "World Tier",
            "World Type": "World Type",
            "Your Boundless Forums Username": "Your Boundless Forums Username",
            Active: "Active",
            ACTIVITIES: "Activities",
            Adventurer: "Adventurer",
            Altitude: "Altitude",
            Amethyst: "Amethyst",
            ANIMAL: "Animals & Nature",
            Any: "Any",
            Atlas: "Atlas",
            Atmosphere: "Atmosphere",
            Australia: "Australia",
            Beacon_plural: "Beacons",
            Beacon: "Beacon",
            Best: "Best",
            Birthday: "Birthday",
            Blast: "Blast",
            Blink: "Blink",
            Body: "Body",
            BOUNDLESS: "Boundless",
            Boundlexx: "Boundlexx",
            Browse: "Browse",
            Brutal: "Brutal",
            Bulk: "Bulk",
            Burn: "Burn",
            Campfire: "Campfire",
            Category: "Category",
            Chieftain: "Chieftain",
            Chill: "Chill",
            City: "City",
            Close: "Close",
            Coal: "Coal",
            Color_plural: "Colors",
            Color: "Color",
            Compactness: "Compactness",
            COMPONENT: "Component",
            Corrosive: "Corrosive",
            Count: "Count",
            Dark: "Dark",
            Data_plural: "Data",
            Data: "Data",
            Diamond: "Diamond",
            Distance: "Distance",
            Emerald: "Emerald",
            Emoji_plural: "Emojis",
            Emoji: "Emoji",
            End: "End",
            Error: "Error",
            Exoworld: "Exoworld",
            Explorer: "Explorer",
            Fierce: "Fierce",
            FLAGS: "Flags",
            Flower: "Flower",
            Foliage: "Foliage",
            FOOD: "Food & Drink",
            Fungus: "Fungus",
            Furance: "Furance",
            Gleam: "Gleam",
            Grass: "Grass",
            Halloween: "Halloween",
            Hamlet: "Hamlet",
            Help: "Help",
            Homeworld: "Homeworld",
            ID_plural: "IDs",
            ID: "ID",
            Inactive: "Inactive",
            Inhospitable: "Inhospitable",
            Inputs: "Inputs",
            Item_plural: "Items",
            Item: "Item",
            Language: "Language",
            Level: "Level",
            Light: "Light",
            Location: "Location",
            Locked: "Locked",
            Lovestruck: "Lovestruck",
            Lucent: "Lucent",
            Lush: "Lush",
            Machine: "Machine",
            Mass: "Mass",
            Master: "Master",
            Mayor: "Mayor",
            Metal: "Metal",
            Mine: "Mine",
            Miscellaneous: "Miscellaneous",
            Neutral: "Neutral",
            Next: "Next",
            No: "No",
            None: "None",
            Normal: "Normal",
            OBJECTS: "Objects",
            Oortian: "Oortian",
            Oortmas: "Oortmas",
            Outpost: "Outpost",
            Output: "Output",
            Pathfinder: "Pathfinder",
            PEOPLE: "People & Body",
            Percent: "Percent",
            Permissions: "Permissions",
            Pioneer: "Pioneer",
            Place: "Place",
            Placid: "Placid",
            Plant: "Plant",
            Power: "Power",
            Prestige: "Prestige",
            Prev: "Prev",
            Price: "Price",
            Recipe_plural: "Recipes",
            Recipe: "Recipe",
            Region: "Region",
            Resources: "Resources",
            Rift: "Rift",
            Rock: "Rock",
            Ruby: "Ruby",
            Rugged: "Rugged",
            Sapphire: "Sapphire",
            Savage: "Savage",
            Settlement_plural: "Settlements",
            Settlement: "Settlement",
            Shock: "Shock",
            Single: "Single",
            Size: "Size",
            SMILEY: "Smiley & Emotion",
            Soil: "Soil",
            Sovereign: "Sovereign",
            Start: "Start",
            Status: "Status",
            Subtype: "Subtype",
            SYMBOLS: "Symbols",
            Temperate: "Temperate",
            Theme: "Theme",
            Tier: "Tier",
            Title: "Title",
            Tool_plural: "Tools",
            Topaz: "Topaz",
            Town: "Town",
            Toxic: "Toxic",
            TRAVEL: "Travel & Places",
            Turbulent: "Turbulent",
            Type: "Type",
            Umbris: "Umbris",
            UNCATEGORIZED: "Uncategorized",
            Unknown: "Unknown",
            Username: "Username",
            Village: "Village",
            Voyager: "Voyager",
            Wayfarer: "Wayfarer",
            Wood: "Wood",
            World_plural: "Worlds",
            World: "World",
            XP: "XP",
            Yes: "Yes",

            Block: "Block",
            "Color Variation": "Color Variation",
            "Color Variation_plural": "Color Variations",
            "Loading Color Variation..._plural": "Color Variations...",
            "Color Variation FoundWithCount_plural": "{{ count }} Color Variations Found",
            "Color Variation FoundWithCount": "{{ count }} Color Variation Found",
            "Change Universe": "Change Universe",
            Universe: "Universe",
            "Metal Variation": "Metal Variation",
            "Metal Variation_plural": "Metal Variations",
            "Loading Metal Variation..._plural": "Metal Variations...",
            "Metal Variation FoundWithCount_plural": "{{ count }} Metal Variations Found",
            "Metal Variation FoundWithCount": "{{ count }} Metal Variation Found",
            "Unexpected Error": "Unexpected Error",
            "Reload Application": "Reload Application",
            "Reset Application": "Reset Application",
        },
    },
    it: {
        translation: {
            "(NOT SECURE)": "(NON SICURO)",
            "Active?": "Attivo?",
            "API Documentation": "Documentazione API",
            "At Least #": "Almeno {{ lower }}",
            "At Most #": "Al massimo {{ upper }}",
            "Auto-Detect": "Trova Automaticamente",
            "Average per Chunk": "Media per Chunk",
            "Back to WORLD": "Torna a <0 />",
            "Base Prestige": "Prestigio base",
            "Beacon Boundary_plural": "Confini del Faro",
            "Beacon Boundary": "Confine del faro",
            "Beacon Name": "Nome del Faro",
            "Best World Type": "Miglior tipo dimondo",
            "Between # and #": "Tra {{ lower }} e {{ upper }}",
            "Block Colors": "Colori dei blocchi",
            "Block Drops": "Rilaciato dal blocco",
            "Boundlexx - Unofficial Boundless API": "Boundless API non ufficiale",
            "Boundlexx is an unoffical API for the game Boundless. There is no affiliation with Wonderstruck Games":
                "Boundlexx è un API non ufficiale per il gioco Boundless. Non c'è affiliazione con Wonderstruck Games",
            "Boundlexx UI Updates": "Aggiornamenti Boundlexx UI",
            "Browser Selected": "Mostra selezionato",
            "By Resource": "Per risorsa",
            "Can also be crafted in MACHINE": "Può anche essere creato in <0 />",
            "Can Claim?": "È lottizzabile?",
            "Can Edit?": "È modificabile?",
            "Can Everyone create beacons and plot on your world?":
                "Tutti possono creare un faro e reclamare lotti nel tuo mondo?",
            "Can Everyone edit blocks on your world (outside of plots)?":
                "Tutti possono modificare blocchi nel tuo mondo (fuori dai lotti)?",
            "Can Everyone warp/use portals to your world?":
                "Tutti possono teletrasportarsi/ usare portali verso il tuo mondo?",
            "Can Visit?": "È visitabile?",
            "Cannot open portal between these worlds": "Non può essere aperto un portale tra questi  mondi",
            "Change Language": "Cambia Lingua",
            "Change Theme": "Cambia tema",
            "Claim?": "Claim?",
            "Clear All": "Cancella tutto",
            "Click here to see what changed.": "Clicca qui per vedere cosa cambia",
            "Color FoundWithCount_plural": "{{ count }} Colori trovati",
            "Color FoundWithCount": "{{ count }} Colore trovato",
            "Color Mixer": "Color mixer",
            "Color-Cycling": "Color-Cycling",
            "Copy to Clipboard": "Copia negli appunti",
            "Core Liquid": "Liquido del nucleo",
            "Crafted in Hand": "Creabile a mano",
            "Crafting Calculator": "Calcolatore di creazione",
            "Creative World": "Creative World",
            "Creature Drops": "Rilasciato della creatura",
            "Current Colors": "Colori attuali",
            "Current Resources": "Risorse attuali",
            "Damage Calculator": "Calcolatore del danno",
            "Default Colors": "Colori predefiniti",
            "Directions to help players find the portal to your world":
                "Indicazioni per aiutare i giocatori a trovare un portale per il tuo mondo",
            "Distance Below Surface": "Distanza dallaerficie",
            "Distance Calculator": "Calolatore di distanza",
            "Do you plan to renew this world?": "Hai intenzione di rinnovare questo mondo?",
            "Does Not Expire": "Non scade",
            "Edit?": "Modifica?",
            "Embedded Resource": "Risorse incastonate",
            "Embedded Resources": "Risorse incorporate",
            "Emoji FoundWithCount_plural": "{{ count }} Emojis trovati",
            "Emoji FoundWithCount": "{{ count }} Emoji trovato",
            "EU Central": "EU Centrale",
            "Exo Embedded Resource": "Risorse incastonate dell’ Exo",
            "Exo Surface Resource": "Risorse incastonate dell’ Exo",
            "Farming Data": "Dati coltivazione",
            "Filters cannot be hidden when you have active filters. Clear existing filters before hiding them.":
                "I filtri non possono essere nascosti se hai filtri attivi. Cancella i filtri esistenti pima di nasconderli",
            "Find By Color": "Trova per colore",
            "Find Color": "Trova colore",
            "Find Color/World By Item_plural": "Trova colori/mondi per oggetto",
            "Find Item/World By Color_plural": "Trova oggetti/mondi per colore",
            "Find World by Resource_plural": "Trova mondo per risorsa",
            "Find Worlds by Color": "Trova mondi per colore",
            "Find Worlds with Resource": "Trova mondi con questa risorsa",
            "First seen": "Prima vista",
            "Forge Simulator": "Simulatore di centraforgia",
            "Forge Traits": "Tratti della forgia",
            "Forum Generator": "Generatore forum",
            "Forum Post": "Post sul forum",
            "Forum Template Generator": "Generatore modello forum",
            "Generate Another": "Crea  un altro",
            "Generate Template": "Crea un modello",
            "Generating Template...": "Creando un modello",
            "Gleambow Racing": "Corsa al Gleambaleno",
            "Goo Helper": "Aiutante Goo",
            "Great City": "Grande città",
            "Has Colors?": "Ha colori?",
            "Hide Filters": "Nascondi Filtri",
            "Hide Groups": "Nascondi Gruppi",
            "Hiding Filters": "Nascondi filtri",
            "In-game Name_plural": "Nomi in gioco",
            "In-game Name": "Nome in gioco",
            "Initial Resources": "Risorse iniziali",
            "Is Beacon compactness enabled?": "Beacon compactness è attiva?",
            "Is Locked?": "E’ chiuso?",
            "Is Resource?": "E’ una risorsa?",
            "Item FoundWithCount_plural": "{{ count }} Oggetti trovati",
            "Item FoundWithCount": "{{ count }} Oggetto trovato",
            "Item Name": "Nome dell'oggetto",
            "Last appeared": "Apparso l’ultima volta",
            "Last available transform": "Ultima trasformazione disponibile",
            "Last Updated": "Ultimo aggiornamento",
            "LIQUID Preferred": "<0 /> Preferito",
            "LIQUID Required": "<0 /> Richiesto",
            "Loading Color..._plural": "Caricando coori",
            "Loading Colors...": "Caricando i colori",
            "Loading Data..._plural": "Caricando dati",
            "Loading Distance...": "Caricando la distanza",
            "Loading Item..._plural": "Caricando oggetti",
            "Loading Item...": "Caricando oggetto",
            "Loading Recipe..._plural": "Caricando ricette",
            "Loading Recipe...": "Caricando ricetta",
            "Loading Request Basket..._plural": "Caricando Cesti di richiesta",
            "Loading Resource..._plural": "Caricando risorse",
            "Loading Resources...": "Caricando le risorse",
            "Loading Shop Stand..._plural": "Caricando Chioschi",
            "Loading Sovereign Color..._plural": "Caricando colori dei Sovereign",
            "Loading World..._plural": "Caricando mondi",
            "Loading World...": "Caricando il mondo",
            "Loading Worlds...": "Caricamento mondi",
            "Main Type": "Tipo principale",
            "Max Life": "Vita massima",
            "Max World Tier": "Livello massimo del mondo",
            "Min World Tier": "Livello minimo del mondo",
            "Min. Conduits": "Condotti minimi",
            "Min. Cost": "Costo minimo",
            "Min. Open Cost": "Costo minimo di apertura",
            "Mint Value": "Valore conio",
            "Misc.": "Misto",
            "New Exoworld color!": "Nuovo colore in un esomondo",
            "New Sovereign Color!": "Nuovo colore in un sovereign",
            "No distance information found": "Nessuna informazione sulla distanza trovata",
            "No Group": "Nessun Gruppo",
            "No patch notes found": "Nessuna nota di aggiornamento trovato",
            "No Recipes Found": "Nessuna ricetta trovata",
            "No Value": "Nessun valore",
            "Number of Plots": "Numero di lotti",
            "Number of Regions": "Numero di regioni",
            "Oort shards": "Schegge di Oort",
            "Oort shards/hr": "Schegge di Oort/ora",
            "Orbited World": "Mondo orbitato",
            "Page Not Found": "Pagina non trovata",
            "Portal Directions": "Indicazioni per il portale",
            "Portal Requirements": "Requisiti portale",
            "Post this in your post 'Body'": "Invia come corpo del post",
            "Post this in your post 'Title'": "Invia come titolo del post",
            "Provide an update link back to this form automatically filled out for quick update?":
                "Fornisci un link di aggiornamento in questo formato compilato automaticamente per un aggiornamento rapido?",
            "Public Visit?": "Visita pubblica?",
            "Recipe # of #": "Ricetta {{ current }} di {{ total }}",
            "Recipe FoundWithCount_plural": "{{ count }} Ricetta",
            "Recipe FoundWithCount": "{{ count }} Ricette",
            "Request Basket FoundWithCount_plural": "{{ count }} Cesti di richiesta",
            "Request Basket FoundWithCount": "{{ count }} Cesto di richiesta",
            "Request Basket_plural": "Cesti di richiesta",
            "Request Basket": "Cesto di richiesta",
            "Requires # Level in Skill and a Pie_plural": "Richiede {{ count }} livelli in Abilità  e  Pasticcio",
            "Requires # Level in Skill_plural": "Richiede {{ count }} livelli in",
            "Requires # Level in Skill": "Richiede {{ count }} livello in",
            "Requires Backer Tier": "Richiede sostenitore di livello",
            "Requires Event": "Richiede l'evento",
            "Requires Level": "Richiede livello",
            "Requires Machine": "Richiede Macchinario",
            "Requires Skill": "Richiede abilità",
            "Reset Data": "Cancella dati",
            "Resource Block": "Blocco sorgente",
            "Resource Data": "Dati sorgente",
            "Resources (Blocks)": "Sorgenti",
            "Search Color": "Cerca colori",
            "Search Emoji": "Cerca Emojis",
            "Search Item": "Cerca oggetti",
            "Search World": "Cerca mondi",
            "See Updates": "Vedi aggiornamenti",
            "Select Category": "Seleziona categoria",
            "Select Color": "Seleziona colore",
            "Select Item": "Seleziona oggetto",
            "Select Orbited World": "Seleziona mondo orbitato ",
            "Select Subtype": "Seleziona sottotipo",
            "Select Type": "Seleziona tipo",
            "Select World 1": "Seleziona mondo 1",
            "Select World 2": "Seleziona mondo 2",
            "Select World": "Seleziona mondo",
            "Server Region": "Regione del server",
            "Shop Stand FoundWithCount_plural": "{{ count }} Chioschi",
            "Shop Stand FoundWithCount": "{{ count }} Chiosco",
            "Shop Stand_plural": "Chioschi",
            "Shop Stand": "Chiosco",
            "Show Filters": "Mostra Filtri",
            "Show Groups": "Mostra Gruppi",
            "Show on Atlas": "Mostra sull'Atlante",
            "Sovereign Color FoundWithCount_plural": "{{ count }} Colori Sovereign",
            "Sovereign Color FoundWithCount": "{{ count }} Colore Sovereign",
            "Sovereign Color_plural": "Colori Sovereign",
            "Sovereign Details": "Dettagli Sovereign",
            "Sovereign Item Color_plural": "Colori degli oggetti del Sovereign",
            "Sovereign World": "Sovereign World",
            "Surface Liquid": "Liquido in superficie",
            "Surface Resource": "Risorsa di superficie",
            "Surface Resources": "Risorse di superficie",
            "The Boundless Lexicon": "The Boundless Lexicon",
            "There is a new version of Boundlexx UI.": "C'è una uova versione di Boundlexx UI.",
            "Tint from": "Tinta da",
            "Unknown Error occured. Please try again later.": "Errore sconsciuto. Per favore riprova più tardi",
            "Update Link?": "Aggiorna link?",
            "Update Now": "Aggiorna adesso",
            "Updates will automatically be applied when you close all tabs":
                "Gli aggiornamenti saranno applicati automaticamente alla chiusura di tutte le finestre",
            "US East": "US Est",
            "US West": "US Ovest",
            "Used In_plural": "Usato in",
            "Warp Cost": "Costo teletrasporto",
            "Will Renew?": "Si rinnoverà?",
            "World 1": "Mondo 1",
            "World 2": "Mondo 2",
            "World Atlas": "Atlante del mondo",
            "World Capital": "Capitale del mondo",
            "World Class": "Classe del mondo",
            "World FoundWithCount_plural": "{{ count }} Mondi trovati",
            "World FoundWithCount": "{{ count }} Mondo trovato",
            "World Image": "Immagine mondo",
            "World Name": "Nome mondo",
            "World Rank": "Classifica Mondo",
            "World Tier": "Livello mondo",
            "World Type": "Tipo del mondo",
            "Your Boundless Forums Username": "Il tuo username nel forum di Boundless",
            Active: "Attivo",
            ACTIVITIES: "Attività",
            Adventurer: "Adventurer",
            Altitude: "Altezza",
            Amethyst: "Ametista",
            ANIMAL: "Animali e natura",
            Any: "Qualunque",
            Atlas: "Atlante",
            Atmosphere: "Atmosfera",
            Australia: "Australia",
            Beacon_plural: "Fari",
            Beacon: "Faro",
            Best: "Migliore",
            Birthday: "Compleanno",
            Blast: "Esplosivo",
            Blink: "Blink",
            Body: "Corpo",
            BOUNDLESS: "Boundless",
            Boundlexx: "Boundlexx",
            Browse: "Visualizza",
            Brutal: "Brutale",
            Bulk: "In blocco",
            Burn: "Bruciante",
            Campfire: "Falò",
            Category: "Categoria",
            Chieftain: "Chieftain",
            Chill: "Gelido",
            City: "Città",
            Close: "Chiudi",
            Coal: "Carbone",
            Color_plural: "Colori",
            Color: "Colore",
            Compactness: "Compactness",
            COMPONENT: "Componente",
            Corrosive: "Corrosivo",
            Count: "Conteggio",
            Dark: "Scuro",
            Data_plural: "Dati",
            Data: "Dato",
            Diamond: "Diamond",
            Distance: "Distanza",
            Emerald: "Smeraldo",
            Emoji_plural: "Emojis",
            Emoji: "Emoji",
            End: "Fine",
            Error: "Errore",
            Exoworld: "Exoworld",
            Explorer: "Explorer",
            Fierce: "Feroce",
            FLAGS: "Bandiere",
            Flower: "Fiore",
            Foliage: "Fogliame",
            FOOD: "Cibo e Bevande",
            Fungus: "Funghi",
            Furance: "Fornace",
            Gleam: "Gleam",
            Grass: "Prato",
            Halloween: "Halloween",
            Hamlet: "Borgo",
            Help: "Aiuto",
            Homeworld: "Homeworld",
            ID_plural: "IDs",
            ID: "ID",
            Inactive: "Inattivo",
            Inhospitable: "Inospitale",
            Inputs: "Ingressi",
            Item_plural: "Oggetti",
            Item: "Oggetto",
            Language: "Lingua",
            Level: "Livello",
            Light: "Chiaro",
            Location: "Luogo",
            Locked: "Chiuso",
            Lovestruck: "Loverstruck",
            Lucent: "Lucente",
            Lush: "Rigoglioso",
            Machine: "Macchinario",
            Mass: "Ingrosso",
            Master: "Master",
            Mayor: "Sindaco",
            Metal: "Metallo",
            Mine: "Miniera",
            Miscellaneous: "Misto",
            Neutral: "Neutrale",
            Next: "Prossimo",
            No: "No",
            None: "Nessuno",
            Normal: "Normale",
            OBJECTS: "Oggetti",
            Oortian: "Oortian",
            Oortmas: "Oortmas",
            Outpost: "Avamposto",
            Output: "Risultato",
            Pathfinder: "Pathfinder",
            PEOPLE: "People & Body",
            Percent: "Percentuale",
            Permissions: "Permessi",
            Pioneer: "Pioneer",
            Place: "Posto",
            Placid: "Aspro",
            Plant: "Pianta",
            Power: "Energia",
            Prestige: "Prestigio",
            Prev: "Precedente",
            Price: "Prezzo",
            Recipe_plural: "Ricette",
            Recipe: "Ricetta",
            Region: "Regione",
            Resources: "Risorse",
            Rift: "Rift",
            Rock: "Roccia",
            Ruby: "Rubino",
            Rugged: "Rugged",
            Sapphire: "Zaffiro",
            Savage: "Savage",
            Settlement_plural: "Insediamenti",
            Settlement: "Insediamento",
            Shock: "Elettrico",
            Single: "Singolo",
            Size: "Taglia",
            SMILEY: "Smiley & Emotion",
            Soil: "Terreno",
            Sovereign: "Sovereign",
            Start: "Inizio",
            Status: "Stato",
            Subtype: "Sottotipo",
            SYMBOLS: "Simboli",
            Temperate: "Temperato",
            Theme: "Tema",
            Tier: "Livello",
            Title: "Titolo",
            Tool_plural: "Strumento",
            Topaz: "Topazio",
            Town: "Citta",
            Toxic: "Tossico",
            TRAVEL: "Viaggi e posti",
            Turbulent: "Turbolento",
            Type: "Tipo",
            Umbris: "Umbris",
            UNCATEGORIZED: "Non categorizzato",
            Unknown: "Sconosciuto",
            Username: "Nome utente",
            Village: "Villaggio",
            Voyager: "Voyager",
            Wayfarer: "Wayfarer",
            Wood: "Legname",
            World_plural: "Mondi",
            World: "Mondo",
            XP: "XP",
            Yes: "Si",

            Block: "Block",
            "Color Variation": "Color Variation",
            "Color Variation_plural": "Color Variations",
            "Loading Color Variation..._plural": "Color Variations...",
            "Color Variation FoundWithCount_plural": "{{ count }} Color Variations Found",
            "Color Variation FoundWithCount": "{{ count }} Color Variation Found",
            "Change Universe": "Change Universe",
            Universe: "Universe",
            "Metal Variation": "Metal Variation",
            "Metal Variation_plural": "Metal Variations",
            "Loading Metal Variation..._plural": "Metal Variations...",
            "Metal Variation FoundWithCount_plural": "{{ count }} Metal Variations Found",
            "Metal Variation FoundWithCount": "{{ count }} Metal Variation Found",
            "Unexpected Error": "Unexpected Error",
            "Reload Application": "Reload Application",
            "Reset Application": "Reset Application",
        },
    },
    es: {
        translation: {
            "(NOT SECURE)": "(NOT SECURE)",
            "Active?": "Active?",
            "API Documentation": "API Documentation",
            "At Least #": "At Least {{ lower }}",
            "At Most #": "At Most {{ upper }}",
            "Auto-Detect": "Auto-Detect",
            "Average per Chunk": "Average per Chunk",
            "Back to WORLD": "Back to <0 />",
            "Base Prestige": "Base Prestige",
            "Beacon Boundary_plural": "Beacon Boundaries",
            "Beacon Boundary": "Beacon Boundary",
            "Beacon Name": "Beacon Name",
            "Best World Type": "Best World Type",
            "Between # and #": "Between {{ lower }} and {{ upper }}",
            "Block Colors": "Block Colors",
            "Block Drops": "Block Drops",
            "Boundlexx - Unofficial Boundless API": "Boundlexx - Unofficial Boundless API",
            "Boundlexx is an unoffical API for the game Boundless. There is no affiliation with Wonderstruck Games":
                "Boundlexx is an unoffical API for the game Boundless. There is no affiliation with Wonderstruck Games",
            "Boundlexx UI Updates": "Boundlexx UI Updates",
            "Browser Selected": "Browser Selected",
            "By Resource": "By Resource",
            "Can also be crafted in MACHINE": "Can also be crafted in <0 />",
            "Can Claim?": "Can Claim?",
            "Can Edit?": "Can Edit?",
            "Can Everyone create beacons and plot on your world?":
                "Can Everyone create beacons and plot on your world?",
            "Can Everyone edit blocks on your world (outside of plots)?":
                "Can Everyone edit blocks on your world (outside of plots)?",
            "Can Everyone warp/use portals to your world?": "Can Everyone warp/use portals to your world?",
            "Can Visit?": "Can Visit?",
            "Cannot open portal between these worlds": "Cannot open portal between these worlds",
            "Change Language": "Change Language",
            "Change Theme": "Change Theme",
            "Claim?": "Claim?",
            "Clear All": "Clear All",
            "Click here to see what changed.": "Click here to see what changed.",
            "Color FoundWithCount_plural": "{{ count }} Colors Found",
            "Color FoundWithCount": "{{ count }} Color Found",
            "Color Mixer": "Color Mixer",
            "Color-Cycling": "Color-Cycling",
            "Copy to Clipboard": "Copy to Clipboard",
            "Core Liquid": "Core Liquid",
            "Crafted in Hand": "Crafted in Hand",
            "Crafting Calculator": "Crafting Calculator",
            "Creative World": "Creative World",
            "Creature Drops": "Creature Drops",
            "Current Colors": "Current Colors",
            "Current Resources": "Current Resources",
            "Damage Calculator": "Damage Calculator",
            "Default Colors": "Default Colors",
            "Directions to help players find the portal to your world":
                "Directions to help players find the portal to your world",
            "Distance Below Surface": "Distance Below Surface",
            "Distance Calculator": "Distance Calculator",
            "Do you plan to renew this world?": "Do you plan to renew this world?",
            "Does Not Expire": "Does Not Expire",
            "Edit?": "Edit?",
            "Embedded Resource": "Embedded Resource",
            "Embedded Resources": "Embedded Resources",
            "Emoji FoundWithCount_plural": "{{ count }} Emojis Found",
            "Emoji FoundWithCount": "{{ count }} Emoji Found",
            "EU Central": "EU Central",
            "Exo Embedded Resource": "Exo Embedded Resource",
            "Exo Surface Resource": "Exo Surface Resource",
            "Farming Data": "Farming Data",
            "Filters cannot be hidden when you have active filters. Clear existing filters before hiding them.":
                "Filters cannot be hidden when you have active filters. Clear existing filters before hiding them.",
            "Find By Color": "Find By Color",
            "Find Color": "Find Color",
            "Find Color/World By Item_plural": "Find Colors/Worlds By Item",
            "Find Item/World By Color_plural": "Find Items/Worlds By Color",
            "Find World by Resource_plural": "Find World by Resource",
            "Find Worlds by Color": "Find Worlds by Color",
            "Find Worlds with Resource": "Find Worlds with Resource",
            "First seen": "First seen",
            "Forge Simulator": "Forge Simulator",
            "Forge Traits": "Forge Traits",
            "Forum Generator": "Forum Generator",
            "Forum Post": "Forum Post",
            "Forum Template Generator": "Forum Template Generator",
            "Generate Another": "Generate Another",
            "Generate Template": "Generate Template",
            "Generating Template...": "Generating Template...",
            "Gleambow Racing": "Gleambow Racing",
            "Goo Helper": "Goo Helper",
            "Great City": "Great City",
            "Has Colors?": "Has Colors?",
            "Hide Filters": "Hide Filters",
            "Hide Groups": "Hide Groups",
            "Hiding Filters": "Hiding Filters",
            "In-game Name_plural": "In-game Names",
            "In-game Name": "In-game Name",
            "Initial Resources": "Initial Resources",
            "Is Beacon compactness enabled?": "Is Beacon compactness enabled?",
            "Is Locked?": "Is Locked?",
            "Is Resource?": "Is Resource?",
            "Item FoundWithCount_plural": "{{ count }} Items Found",
            "Item FoundWithCount": "{{ count }} Item Found",
            "Item Name": "Item Name",
            "Last appeared": "Last appeared",
            "Last available transform": "Last available transform",
            "Last Updated": "Last Updated",
            "LIQUID Preferred": "<0 /> Preferred",
            "LIQUID Required": "<0 /> Required",
            "Loading Color..._plural": "Loading Colors...",
            "Loading Colors...": "Loading Colors...",
            "Loading Data..._plural": "Loading Data...",
            "Loading Distance...": "Loading Distance...",
            "Loading Item..._plural": "Loading Items...",
            "Loading Item...": "Loading Item...",
            "Loading Recipe..._plural": "Loading Recipes...",
            "Loading Recipe...": "Loading Recipe...",
            "Loading Request Basket..._plural": "Loading Request Baskets...",
            "Loading Resource..._plural": "Loading Resources...",
            "Loading Resources...": "Loading Resources...",
            "Loading Shop Stand..._plural": "Loading Shop Stands...",
            "Loading Sovereign Color..._plural": "Loading Sovereign Colors...",
            "Loading World..._plural": "Loading Worlds...",
            "Loading World...": "Loading World...",
            "Loading Worlds...": "Loading Worlds...",
            "Main Type": "Main Type",
            "Max Life": "Max Life",
            "Max World Tier": "Max World Tier",
            "Min World Tier": "Min World Tier",
            "Min. Conduits": "Min. Conduits",
            "Min. Cost": "Min. Cost",
            "Min. Open Cost": "Min. Open Cost",
            "Mint Value": "Mint Value",
            "Misc.": "Misc.",
            "New Exoworld color!": "New Exoworld color!",
            "New Sovereign Color!": "New Sovereign Color!",
            "No distance information found": "No distance information found",
            "No Group": "No Group",
            "No patch notes found": "No patch notes found",
            "No Recipes Found": "No Recipes Found",
            "No Value": "No Value",
            "Number of Plots": "Number of Plots",
            "Number of Regions": "Number of Regions",
            "Oort shards": "Oort shards",
            "Oort shards/hr": "Oort shards/hr",
            "Orbited World": "Orbited World",
            "Page Not Found": "Page Not Found",
            "Portal Directions": "Portal Directions",
            "Portal Requirements": "Portal Requirements",
            "Post this in your post 'Body'": "Post this in your post 'Body'",
            "Post this in your post 'Title'": "Post this in your post 'Title'",
            "Provide an update link back to this form automatically filled out for quick update?":
                "Provide an update link back to this form automatically filled out for quick update?",
            "Public Visit?": "Public Visit?",
            "Recipe # of #": "Recipe {{ current }} of {{ total }}",
            "Recipe FoundWithCount_plural": "{{ count }} Recipe",
            "Recipe FoundWithCount": "{{ count }} Recipe",
            "Request Basket FoundWithCount_plural": "{{ count }} Request Baskets",
            "Request Basket FoundWithCount": "{{ count }} Request Basket",
            "Request Basket_plural": "Request Baskets",
            "Request Basket": "Request Basket",
            "Requires # Level in Skill and a Pie_plural": "Requires {{ count }} Levels in Skill and a Pie",
            "Requires # Level in Skill_plural": "Requires {{ count }} Levels in Skill",
            "Requires # Level in Skill": "Requires {{ count }} Level in Skill",
            "Requires Backer Tier": "Requires Backer Tier",
            "Requires Event": "Requires Event",
            "Requires Level": "Requires Level",
            "Requires Machine": "Requires Machine",
            "Requires Skill": "Requires Skill",
            "Reset Data": "Reset Data",
            "Resource Block": "Resource Block",
            "Resource Data": "Resource Data",
            "Resources (Blocks)": "Resources (Blocks)",
            "Search Color": "Search Colors",
            "Search Emoji": "Search Emojis",
            "Search Item": "Search Items",
            "Search World": "Search Worlds",
            "See Updates": "See Updates",
            "Select Category": "Select Category",
            "Select Color": "Select Color",
            "Select Item": "Select Item",
            "Select Orbited World": "Select Orbited World",
            "Select Subtype": "Select Subtype",
            "Select Type": "Select Type",
            "Select World 1": "Select World 1",
            "Select World 2": "Select World 2",
            "Select World": "Select World",
            "Server Region": "Server Region",
            "Shop Stand FoundWithCount_plural": "{{ count }} Shop Stands",
            "Shop Stand FoundWithCount": "{{ count }} Shop Stand",
            "Shop Stand_plural": "Shop Stands",
            "Shop Stand": "Shop Stand",
            "Show Filters": "Show Filters",
            "Show Groups": "Show Groups",
            "Show on Atlas": "Show on Atlas",
            "Sovereign Color FoundWithCount_plural": "{{ count }} Sovereign Colors",
            "Sovereign Color FoundWithCount": "{{ count }} Sovereign Color",
            "Sovereign Color_plural": "Sovereign Colors",
            "Sovereign Details": "Sovereign Details",
            "Sovereign Item Color_plural": "Sovereign Item Colors",
            "Sovereign World": "Sovereign World",
            "Surface Liquid": "Surface Liquid",
            "Surface Resource": "Surface Resource",
            "Surface Resources": "Surface Resources",
            "The Boundless Lexicon": "The Boundless Lexicon",
            "There is a new version of Boundlexx UI.": "There is a new version of Boundlexx UI.",
            "Tint from": "Tint from",
            "Unknown Error occured. Please try again later.": "Unknown Error occured. Please try again later.",
            "Update Link?": "Update Link?",
            "Update Now": "Update Now",
            "Updates will automatically be applied when you close all tabs":
                "Updates will automatically be applied when you close all tabs",
            "US East": "US East",
            "US West": "US West",
            "Used In_plural": "Used In",
            "Warp Cost": "Warp Cost",
            "Will Renew?": "Will Renew?",
            "World 1": "World 1",
            "World 2": "World 2",
            "World Atlas": "World Atlas",
            "World Capital": "World Capital",
            "World Class": "World Class",
            "World FoundWithCount_plural": "{{ count }} Worlds Found",
            "World FoundWithCount": "{{ count }} World Found",
            "World Image": "World Image",
            "World Name": "World Name",
            "World Rank": "World Rank",
            "World Tier": "World Tier",
            "World Type": "World Type",
            "Your Boundless Forums Username": "Your Boundless Forums Username",
            Active: "Active",
            ACTIVITIES: "Activities",
            Adventurer: "Adventurer",
            Altitude: "Altitude",
            Amethyst: "Amethyst",
            ANIMAL: "Animals & Nature",
            Any: "Any",
            Atlas: "Atlas",
            Atmosphere: "Atmosphere",
            Australia: "Australia",
            Beacon_plural: "Beacons",
            Beacon: "Beacon",
            Best: "Best",
            Birthday: "Birthday",
            Blast: "Blast",
            Blink: "Blink",
            Body: "Body",
            BOUNDLESS: "Boundless",
            Boundlexx: "Boundlexx",
            Browse: "Browse",
            Brutal: "Brutal",
            Bulk: "Bulk",
            Burn: "Burn",
            Campfire: "Campfire",
            Category: "Category",
            Chieftain: "Chieftain",
            Chill: "Chill",
            City: "City",
            Close: "Close",
            Coal: "Coal",
            Color_plural: "Colors",
            Color: "Color",
            Compactness: "Compactness",
            COMPONENT: "Component",
            Corrosive: "Corrosive",
            Count: "Count",
            Dark: "Dark",
            Data_plural: "Data",
            Data: "Data",
            Diamond: "Diamond",
            Distance: "Distance",
            Emerald: "Emerald",
            Emoji_plural: "Emojis",
            Emoji: "Emoji",
            End: "End",
            Error: "Error",
            Exoworld: "Exoworld",
            Explorer: "Explorer",
            Fierce: "Fierce",
            FLAGS: "Flags",
            Flower: "Flower",
            Foliage: "Foliage",
            FOOD: "Food & Drink",
            Fungus: "Fungus",
            Furance: "Furance",
            Gleam: "Gleam",
            Grass: "Grass",
            Halloween: "Halloween",
            Hamlet: "Hamlet",
            Help: "Help",
            Homeworld: "Homeworld",
            ID_plural: "IDs",
            ID: "ID",
            Inactive: "Inactive",
            Inhospitable: "Inhospitable",
            Inputs: "Inputs",
            Item_plural: "Items",
            Item: "Item",
            Language: "Language",
            Level: "Level",
            Light: "Light",
            Location: "Location",
            Locked: "Locked",
            Lovestruck: "Lovestruck",
            Lucent: "Lucent",
            Lush: "Lush",
            Machine: "Machine",
            Mass: "Mass",
            Master: "Master",
            Mayor: "Mayor",
            Metal: "Metal",
            Mine: "Mine",
            Miscellaneous: "Miscellaneous",
            Neutral: "Neutral",
            Next: "Next",
            No: "No",
            None: "None",
            Normal: "Normal",
            OBJECTS: "Objects",
            Oortian: "Oortian",
            Oortmas: "Oortmas",
            Outpost: "Outpost",
            Output: "Output",
            Pathfinder: "Pathfinder",
            PEOPLE: "People & Body",
            Percent: "Percent",
            Permissions: "Permissions",
            Pioneer: "Pioneer",
            Place: "Place",
            Placid: "Placid",
            Plant: "Plant",
            Power: "Power",
            Prestige: "Prestige",
            Prev: "Prev",
            Price: "Price",
            Recipe_plural: "Recipes",
            Recipe: "Recipe",
            Region: "Region",
            Resources: "Resources",
            Rift: "Rift",
            Rock: "Rock",
            Ruby: "Ruby",
            Rugged: "Rugged",
            Sapphire: "Sapphire",
            Savage: "Savage",
            Settlement_plural: "Settlements",
            Settlement: "Settlement",
            Shock: "Shock",
            Single: "Single",
            Size: "Size",
            SMILEY: "Smiley & Emotion",
            Soil: "Soil",
            Sovereign: "Sovereign",
            Start: "Start",
            Status: "Status",
            Subtype: "Subtype",
            SYMBOLS: "Symbols",
            Temperate: "Temperate",
            Theme: "Theme",
            Tier: "Tier",
            Title: "Title",
            Tool_plural: "Tools",
            Topaz: "Topaz",
            Town: "Town",
            Toxic: "Toxic",
            TRAVEL: "Travel & Places",
            Turbulent: "Turbulent",
            Type: "Type",
            Umbris: "Umbris",
            UNCATEGORIZED: "Uncategorized",
            Unknown: "Unknown",
            Username: "Username",
            Village: "Village",
            Voyager: "Voyager",
            Wayfarer: "Wayfarer",
            Wood: "Wood",
            World_plural: "Worlds",
            World: "World",
            XP: "XP",
            Yes: "Yes",

            Block: "Block",
            "Color Variation": "Color Variation",
            "Color Variation_plural": "Color Variations",
            "Loading Color Variation..._plural": "Color Variations...",
            "Color Variation FoundWithCount_plural": "{{ count }} Color Variations Found",
            "Color Variation FoundWithCount": "{{ count }} Color Variation Found",
            "Change Universe": "Change Universe",
            Universe: "Universe",
            "Metal Variation": "Metal Variation",
            "Metal Variation_plural": "Metal Variations",
            "Loading Metal Variation..._plural": "Metal Variations...",
            "Metal Variation FoundWithCount_plural": "{{ count }} Metal Variations Found",
            "Metal Variation FoundWithCount": "{{ count }} Metal Variation Found",
            "Unexpected Error": "Unexpected Error",
            "Reload Application": "Reload Application",
            "Reset Application": "Reset Application",
        },
    },
};

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: "en",
        debug: true,
        keySeparator: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
